<template>
    <div class="box my-account">
        <div class="invoice">
            <div class="invoice_head">
                <el-radio-group v-model="typeData" @change="typeChange">
                    <el-radio-button :label="item.value" v-for="(item,index) of typeList" :key="index">
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div class="invoice_table">
                <el-table :data="invoiceData">
                    <el-table-column label="订单单号" width="125px" prop="order_no"></el-table-column>
                    <el-table-column label="订单时间" width="95px" prop="create_time"></el-table-column>
                    <el-table-column label="发票金额" width="80px" prop="total_price"></el-table-column>
                    <el-table-column label="发票信息" prop="invoice_org_name">
                        <template slot-scope="scope">
                            发票抬头：{{scope.row.invoice_org_name}}<br/>
                            税号：{{scope.row.invoice_number}}<br/>
                            email：{{scope.row.email}}<br/>
                            <span v-if="scope.row.address_detail !=' '">地址：{{scope.row.address_detail}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请时间" width="95px" prop="apply_time"></el-table-column>
                    <el-table-column label="状态" width="80px" prop="ticket_status">
                        <template slot-scope="scope">
                            {{scope.row.ticket_status | capitalize}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.ticket_status == '2'" type="text" size="small">开票处理中</el-button>
                            <el-button @click="openTheDor(scope.row)" v-else-if="scope.row.ticket_status == 1 && scope.row.audit_status==1" size="small">申请开票</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="invoice_page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.page"
                    :page-size="page.pageSize"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { VoucherInvoicelists, VoucherInvoiceapply } from "@/api/member/member"

    export default {
        name: "invoice",
        data() {
            return {
                typeData: 0,
                total: 0,
                typeList: [
                    {
                        name: "全部",
                        value: 0
                    },
                    {
                        name: "未申请",
                        value: 1
                    },
                    {
                        name: "开具中",
                        value: 2
                    },
                    {
                        name: "已完成",
                        value: 3
                    }
                ],
                page: {
                    page: 1,
                    pageSize: 10
                },
                invoiceData: []
            }
        },
        filters: {
            capitalize: function (value) {
                if (value == 1) {
                    return '未申请'
                } else if (value == 2) {
                    return '已申请'
                } else if (value == 3) {
                    return '已开票'
                }
            }
        },
        mounted() {
            this.VoucherInvoicelistsData()
        },
        methods: {
            // 申请开票
            VoucherInvoiceapplyData(item) {
                let data = {
                    order_no: item.order_no
                }
                VoucherInvoiceapply(data).then(res => {
                    this.VoucherInvoicelistsData()
                    this.$message.success('申请成功！')
                }).catch(e => {
                    this.$message.warning(e.message)
                })
            },
            openTheDor(row) {
                this.$confirm(`订单号：${row.order_no},申请金额为${row.total_price} 是否申请?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.VoucherInvoiceapplyData(row)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消申请！'
                    });
                });
            },
            typeChange() {
                this.page.page = 1
                this.VoucherInvoicelistsData()
            },
            // 消费券发票 / 列表
            VoucherInvoicelistsData() {
                let par = {
                    ...this.page,
                    ticket_status: this.typeData
                }
                VoucherInvoicelists(par).then(res => {
                    let data = res.data
                    this.invoiceData = data.list
                    this.total = data.count
                })
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`)
            },
            handleCurrentChange(val) {
                this.page.page = val
                this.VoucherInvoicelistsData()
                window.scrollTo(0, 0)
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 20px;
  }

  .invoice {
    &_head {
      margin-bottom: 10px;
    }

    &_table {
      margin-bottom: 10px;
    }

    &_page {
      ::v-deep .el-pagination {
        text-align: center;
      }
    }
  }
</style>
